import React, { useState } from 'react';
import NotificationAlert from "react-notification-alert";
import Axios from "axios";


function Login(props) {
    const [username, setUsername] = useState('misadmin');
    const [password, setPassword] = useState('+2pCVDleJVqvuIJAFKMpDHidByrzdXgwaipaL0Kxilc=');
    const notificationAlert = React.useRef();
    const logoImage='../images/lglogin.png';
    const vinayakimage='../images/vinayak.png';
    

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };
    
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleSubmit = (event) => {
        
        event.preventDefault();
        // handle form submission here
        const formData = {
            email:username,
            password:password,
            action:'LOGIN'
        };

        Axios({
            //misadmin	+2pCVDleJVqvuIJAFKMpDHidByrzdXgwaipaL0Kxilc=
            // url: "http://183.82.144.101/ncclr_api_ci/index.php/Api/login?action=Check_Login",
            //url: "http://localhost/maxwell_api_ci/index.php/Api/login?action=Check_Login",
            
            //url: "http://localhost/maxwellapi/dashboard/api.php",
            url: "https://dashboard.maxwellrelocations.com/service/api.php",
            method: "POST",
            responsetype: 'json',
            data: JSON.stringify(formData)
        })
        // Handle the response from backend here
            .then((res) => { 
                //<Redirect to="/admin/dashboard" />
                if(res.data.loginStatus==1){
                    //localStorage.setItem('userid',res.data.results[0].USERID);
                    localStorage.setItem('userid',res.data.UserId);
                    localStorage.setItem('UserEmail',res.data.UserEmail);
                    localStorage.setItem('loginStatus',1);
                    //window.location.href='/admin/dashboard/';	
                    window.location.href='/';	
                    //navigate("/admin/dashboard/");
                    
                }else{			
                    
                    var type = "danger";						
                    var options = {};
                    options = {
                    place: 'tc',
                    message: (
                        <div className='font-weight-bold'>
                        Invalid username or password!!
                        </div>
                    ),
                    type: type,
                    icon: "nc-icon nc-bell-55",
                    autoDismiss: 5
                    };  
                    notificationAlert.current.notificationAlert(options);                               
                        
                }					
            })
                
            // Catch errors if any
            .catch((err) => {
                
             });
    };
    
 
  	
    
    return(  
        <>
        <NotificationAlert ref={notificationAlert} />
        <div className="bg-primary" id="layoutAuthentication">
        
            <div id="layoutAuthentication_content">
                <main>
                
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header"><h3 className="text-center font-weight-light my-4"> <img src={vinayakimage} alt="PNG image" /></h3></div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit} >
                                            <div className="form-floating mb-3">
                                                <input className="form-control" id="inputEmail" type="text" placeholder="name@example.com" value={username} onChange={handleUsernameChange}/>
                                                <label for="Username">Username</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input className="form-control" id="inputPassword" type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                                                <label for="inputPassword">Password</label>
                                            </div>
                                            <div className="form-check mb-3">
                                                {/*<input className="form-check-input" id="inputRememberPassword" type="checkbox" value="" />
                                                 <label className="form-check-label" for="inputRememberPassword">Remember Password</label>  */}
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-4 mb-0 ">
                                                 {/* <a className="small" href="password.html">Forgot Password?</a>  */}
                                                <button className="btn btn-primary" type="submit" >Login</button>
                                            </div>
                                        </form>
                                    </div>
                                   
                                    {/* <div className="card-footer text-center py-3">
                                        <div className="small"><a href="register.html">Need an account? Sign up!</a></div> 
                                    </div> */}
                                    <div className="card-header"><h3 className="text-center font-weight-light my-4"> <img src={logoImage} alt="PNG image" /></h3></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div id="layoutAuthentication_footer">
                <footer className="py-4 bg-light mt-auto">
                    <div className="container-fluid px-4">
                        <div className="d-flex align-items-center justify-content-between small">
                            <div className="text-muted">Copyright &copy; Your Website 2023</div>
                            <div>
                                <a href="#">Privacy Policy</a>
                                &middot;
                                <a href="#">Terms &amp; Conditions</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
        </>
        
        
    );
}
export default Login;